import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/common/Layout/layout"
import SEO from "../../components/common/SEO/seo"
import BannerPage from "../../components/banner/BannerPage/bannerPage"
import SectionTextImage from "../../components/section/TextImage/textImage"
import CallToAction from "../../components/section/CallToAction/callToAction"

const ImpresionDigitalPage = () => {
    const data = useStaticQuery(graphql`
    query {
      impresionDigitalImage1: file(relativePath: { eq: "images/sections/produccion-grafica/impresion-digital/impresion-digital-pequeno-formato-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      impresionDigitalImage2: file(relativePath: { eq: "images/sections/produccion-grafica/impresion-digital/impresion-digital-gran-formato-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
            <SEO
                title="Imprenta Digital"
                description="Imprenta en Barcelona experta en la impresión digital de pequeño y gran formato con la última tecnología."
            />

            <BannerPage
                title="Expertos en impresión digital"
                text="Imprimimos tus ideas"
                color="blue"
            />

            <SectionTextImage
                title1="Impresión digital"
                title2="pequeño formato"
                text={<Fragment>
                    La impresión digital de formato pequeño es aquella indicada para la <strong>producción de tirajes cortos y muy personalizados</strong>. En ella encontramos: <br /><br />
                    <ul className="text-left">
                        <li>Papelería (Tarjetas, cartas, sobres, albaranes, hojas de ruta, sellos)</li>
                        <li>Flyers, trípticos, dípticos</li>
                        <li>Etiquetas y pegatinas</li>
                    </ul>
                </Fragment>}
                image={data.impresionDigitalImage1.childImageSharp.fluid}
                imageAlt="Impresión Digital Barcelona - Agencia Marketing Digital"
                color="blue"
                containerTextRight={false}
            />

            <SectionTextImage
                title1="Impresión digital"
                title2="gran formato"
                text={<Fragment>
                    Dentro de la impresión digital, también existe la posibilidad de llevar a cabo la <strong>producción de piezas de gran formato</strong>:<br /><br />
                    <ul className="text-left">
                        <li>Cartelería, Vinilos y Roll ups</li>
                        <li>Rótulos, pancartas, vallas publicitarias</li>
                        <li>Stands para ferias y eventos</li>
                    </ul>
                </Fragment>}
                image={data.impresionDigitalImage2.childImageSharp.fluid}
                imageAlt="Impresión Digital Barcelona - Agencia Marketing Digital"
                color="blue"
                containerTextRight={true}
            />

            <CallToAction
                color="blue"
                title="¿Necesita tu empresa una imprenta digital?"
                text={<Fragment>
                    ¡Somos expertos en <strong>impresión digital en Barcelona</strong>!
                </Fragment>}
                button="¡Llámanos y pide presupuesto!"
                buttonUrl="/contacto"
            />

        </Layout>
    )

}
export default ImpresionDigitalPage
